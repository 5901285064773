type Environment = {
  REACT_APP_AZURE_OAUTH_APPLICATION_ID: string;
  REACT_APP_AZURE_OAUTH_TENANCY: string;
  REACT_APP_ROLLBAR_TOKEN: string;
  REACT_APP_URL: string;
};

type WindowEnvironment = Window & typeof globalThis & { _env_?: Environment };

/**
 * Function to retrieve the environment variables. The variables are pulled from the window object
 * in production and process.env in development.
 * @returns {Environment} - The environment variables
 */
export default function getEnvironment(): Environment {
  if (process.env.NODE_ENV === "production") {
    const windowEnv: WindowEnvironment = window;
    return windowEnv._env_;
  }
  return {
    REACT_APP_AZURE_OAUTH_APPLICATION_ID:
      process.env.REACT_APP_AZURE_OAUTH_APPLICATION_ID,
    REACT_APP_AZURE_OAUTH_TENANCY: process.env.REACT_APP_AZURE_OAUTH_TENANCY,
    REACT_APP_ROLLBAR_TOKEN: process.env.REACT_APP_ROLLBAR_TOKEN,
    REACT_APP_URL: process.env.REACT_APP_URL,
  };
}
